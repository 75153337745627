
      .message_banner{
        background: #ACCBE1;
        border: 1px solid #000;
        color: #000;
        font-size: 120%;
        height: 10%;
        padding: 2%;
        border-width: 0px 0px 10px 0px;
      }
  body {
      font-family: 'Helvetica', 'Arial', 'Times New Roman', Times, serif;
        text-align: center;
        background-color: #f0f0f0;  
    }
    
    .container {
        max-width: 600px;
        margin: 50px auto;
    }
    
    .profile img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
    }
    
    .link-button {
        display: block;
        background-color: #4CAF50; /* Example color */
        color: white;
        padding: 15px 25px;
        text-decoration: none;
        margin-bottom: 10px;
        border-radius: 5px;
    } 
    
    #footer{
      margin-top: 250px;
      height: 50px;
    }

    @media only screen and (min-width: 769px) {
    
    }

    @media only screen and (max-width: 768px ){
      
    }